
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import { firestore } from '@/plugins/firebase'
import { useProfile } from '@/composables/profile/useProfile'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '../base/BaseModal.vue'
import ConnectButton from './ConnectButton.vue'
import { useAxios } from '@/composables/useAxios'
import ConnectAccountButton from './ConnectAccountButton.vue'
import DisconnectAccountButton from './DisconnectAccountButton.vue'

interface PageProps {
  id: string
  name: string
  picture: any
  source: string
  username?: string
  link?: string
}

interface TiktokAccount {
  id: string
  name: string
  picture: string
  source: string
}

export default defineComponent({
  components: {
    BaseModal,
    ConnectButton,
    ConnectAccountButton,
    DisconnectAccountButton
},
  setup () {
    const selectedPageId = ref<string>('')
    const hasPages = computed(() => pagesList.value.length > 0)
    const isConnectingAccount = ref(false)

    const { profile } = useProfile()

    const selectedPage = ref<TiktokAccount>()

    const pagesList = ref<PageProps[]>([])

    const image = computed(() => require('@/assets/img/tiktok-logo-full.png'))

    const {
      isActive: isOpen,
      setInactive: closeModal,
    } = useToggle(false)

    const {
      isActive: isConnected,
      setActive: setConnected,
      setInactive: setDisconnected,
    } = useToggle(false)

    const hasSelectedPage = computed(() => selectedPage.value !== undefined)

    const selectedPageInfo = computed(() => {
      return pagesList.value.find((p) => p.id === selectedPage.value?.id)
    })

    const handleCompleteAction = async () => {
      if (selectedPageId.value) {
        isConnectingAccount.value = true
        const { httpFunctions } = await useAxios()
        await httpFunctions.put(`/socialLogin-requests/select-page`, {
          source: 'tiktok',
          profileId: profile.value.id,
          pageId: selectedPageId.value
        })
      }

      isConnectingAccount.value = false
      closeModal()
    }

    const disconnect = async () => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/disconnect/${profile.value.id}/tiktok`)
    }

    const restart = () => {
      setDisconnected()
      selectedPage.value = undefined

      selectedPageId.value = ''
    }

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/profile/manyrobot-profile.svg')
    }

    onMounted(async () => {
      firestore.collection('socialAccounts')
        .doc(unref(profile).id)
        .onSnapshot((accountsDoc) => {
          selectedPage.value = undefined

          if (accountsDoc.exists) {
            const accountsData = accountsDoc.data()

            if (accountsData) {
              const { tiktok } = accountsData

              if (tiktok) {
                setConnected()

                selectedPage.value = {
                  id: tiktok.open_id,
                  name: tiktok.display_name,
                  picture: tiktok.avatar_url_100,
                  source: 'tiktok'
                }

                return
              }
            }
          }

          setDisconnected()
          selectedPage.value = undefined
        })
    })

    return {
      selectedPageId,
      hasPages,
      isConnectingAccount,
      closeModal,
      pagesList,
      selectedPageInfo,
      hasSelectedPage,
      selectedPage,
      image,
      isConnected,
      isOpen,
      disconnect,
      imageFallback,
      handleCompleteAction,
      restart
    }
  },
})
